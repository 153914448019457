export default [
  {
    id: 17,
    type: 'book_of_giza',
    title: ['Book', 'of Giza'],
    desc: 'Embark on a journey to the ancient pyramid and uncover the most valuable treasure of all time - the Book of Giza. If fortune smiles upon you, you may also receive 10 free spins featuring a Special Expanding Symbol. Egypt awaits!',
    descShort:
      'Discover the treasure of all time - the Book of Giza. If fortune smiles upon you, you will receive 10 free spins.',
    img: '/games/book-of-giza/hero.png',
    bg: '/games/book-of-giza/bg.jpg',
    preview: '/games/book-of-giza/preview.png',
    screens: [
      '/games/book-of-giza/1.jpg',
      '/games/book-of-giza/2.jpg',
      '/games/book-of-giza/3.jpg',
      '/games/book-of-giza/4.jpg',
      '/games/book-of-giza/5.jpg',
      '/games/book-of-giza/6.jpg',
      '/games/book-of-giza/7.jpg',
    ],
    info: {
      category: 'Slot',
      rtp: '98,30%',
      volatility: 'Medium',
      maxWin: 'x5000',
    },
    releaseDate: 'February 2024',
  },
  {
    id: 18,
    type: 'food_truck',
    title: ['Food', 'Truck'],
    desc: 'Order a delectable meal from the food truck where our chef will present you with his signature dish. After savoring your meal, indulge in the opportunity to win big with 15 free spins on the tranquil beach with an x3 multiplier on all winnings!',
    descShort:
      'Indulge in the opportunity to win big with 15 free spins on the tranquil beach with an x3 multiplier on all winnings!',
    img: '/games/food-truck/hero.png',
    bg: '/games/food-truck/bg.jpg',
    preview: '/games/food-truck/preview.png',
    screens: ['/games/food-truck/1.jpg', '/games/food-truck/2.jpg', '/games/food-truck/3.jpg'],
    info: {
      category: 'Slot',
      rtp: '96,90%',
      volatility: 'High',
      maxWin: 'x9000',
    },
    releaseDate: 'February 2024',
  },
  {
    id: 19,
    type: 'la_mafia',
    title: ['La', 'Mafia'],
    desc: 'Welcome to visit the renowned Mafia boss. Please make yourself comfortable, pour yourself some whiskey, take a spin, and the boss will reward you with significant winnings.',
    descShort:
      'Welcome to visit the renowned Mafia boss. Pour yourself some whiskey, take a spin, and get rewarded with significant winnings.',
    img: '/games/la-mafia/hero.png',
    bg: '/games/la-mafia/bg.jpg',
    preview: '/games/la-mafia/preview.png',
    screens: ['/games/la-mafia/1.jpg', '/games/la-mafia/2.jpg', '/games/la-mafia/3.jpg'],
    info: {
      category: 'Slot',
      rtp: '97.07%',
      volatility: 'High',
      maxWin: 'x10000',
    },
    releaseDate: 'March 2024',
  },
  {
    id: 20,
    type: 'sheriff_coins',
    title: ["Sheriff's", 'Coins'],
    desc: "Flip the coin to see if you are lucky enough to win. If you are lucky – then increase the number of coins! You can get enormous winnings if all of them land the right way. Play big or small - it's your choice. Sheriff approves!",
    descShort:
      'Flip one coin, flip two coins or flip them all! The more coins land your way - the bigger your payout gets!',
    img: '/games/sheriff_coins/hero.png',
    bg: '/games/sheriff_coins/bg.jpg',
    preview: '/games/sheriff_coins/preview.png',
    screens: [
      '/games/sheriff_coins/1.jpg',
      '/games/sheriff_coins/2.jpg',
      '/games/sheriff_coins/3.jpg',
    ],
    info: {
      category: 'Instant',
      rtp: '95.00%',
      volatility: 'High',
      maxWin: 'x3800',
    },
    releaseDate: 'March 2024',
  },
  {
    id: 21,
    type: 'fruit_luck',
    title: ['Fruit', 'Luck'],
    desc: "Who doesn't love fruits? Everyone does! But what if these fruits bring you LUCK? Then it's time to turn those fruits into a lucrative cocktail! This is the high volatility adrenaline-juice-pumping trip.",
    descShort:
      'Juicy fruits are geady to get smashed into your HUGE winnings! Start turning fruits into cash NOW!',
    img: '/games/fruit-luck/hero.png',
    bg: '/games/fruit-luck/bg.jpg',
    preview: '/games/fruit-luck/preview.png',
    screens: ['/games/fruit-luck/1.jpg', '/games/fruit-luck/2.jpg', '/games/fruit-luck/3.jpg'],
    info: {
      category: 'Slot',
      rtp: '96.70%',
      volatility: 'High',
      maxWin: 'x5000',
    },
    releaseDate: 'June 2024',
  },
  {
    id: 22,
    type: 'future_fruits',
    title: ['Future', 'Fruits'],
    desc: 'In the future, fruits will remain as valuable as ever. Moreover, they are also believed to bring good luck. Join the thrill of FUTURE FRUITS slot game and experience the succulent adventure and enormous payouts!',
    descShort: 'Join the thrill of the succulent adventure and enormous payouts!',
    img: '/games/future-fruits/hero.png',
    bg: '/games/future-fruits/bg.jpg',
    preview: '/games/future-fruits/preview.png',
    screens: [
      '/games/future-fruits/1.jpg',
      '/games/future-fruits/2.jpg',
      '/games/future-fruits/3.jpg',
      '/games/future-fruits/4.jpg',
      '/games/future-fruits/5.jpg',
    ],
    info: {
      category: 'Slot',
      rtp: '97,60%',
      volatility: 'Medium',
      maxWin: 'x5000',
    },
    releaseDate: 'June 2024',
  },
  {
    id: 130,
    type: 'los_enamorados',
    title: ['Los', 'Enamorados'],
    desc: "In this game, you will assist our heroic lover in winning the heart of his beloved. Sing serenades under the balcony, collect Mega Blocks for Mega Wins, and watch as our hero's irresistible charm wins over even the most resistant hearts.",
    descShort:
      "Sing serenades under the balcony, collect Mega Blocks for Mega Wins, and watch as our hero's irresistible charm wins!",
    img: '/games/los-enamorados/hero.png',
    bg: '/games/los-enamorados/bg.jpg',
    preview: '/games/los-enamorados/preview.png',
    screens: [
      '/games/los-enamorados/1.jpg',
      '/games/los-enamorados/2.jpg',
      '/games/los-enamorados/3.jpg',
    ],
    info: {
      category: 'Slot',
      rtp: '98,10%',
      volatility: 'High',
      maxWin: 'x10000',
    },
    releaseDate: '',
  },
  {
    id: 135,
    type: 'neon_ferocity',
    title: ['Neon', 'Ferocity'],
    desc: 'Dark cyberpunk city awaits its hero! Jump into the action as the Cybercorp off the leash. Find the criminal gangs and strip them off their money! High volatility, high stakes, HUGE payouts!',
    descShort:
      'Furries and neon lights. Cybercop and shady deals. Make the odds work in your favor!',
    img: '/games/neon-ferocity/hero.png',
    bg: '/games/neon-ferocity/bg.jpg',
    preview: '/games/neon-ferocity/preview.png',
    screens: [
      '/games/neon-ferocity/1.jpg',
      '/games/neon-ferocity/2.jpg',
      '/games/neon-ferocity/3.jpg',
    ],
    info: {
      category: 'Slot',
      rtp: '97.12%',
      volatility: 'High',
      maxWin: 'x5000',
    },
    releaseDate: '',
  },
  {
    id: 138,
    type: 'yokai_hunter',
    title: ['Yokai', 'Hunter'],
    desc: 'Stunning art based on the true Japanese legends provides enough fun while the reels are spinning. One of the most beautiful games made so far. High volatility makes every spin of the Hunter an unpredictable trophy hunt! So let the hunt begin!',
    descShort:
      'Hunt demons as the young and beautiful Japanese huntress. Get your prey and convert it into generous payouts!',
    img: '/games/yokai-hunter/hero.png',
    bg: '/games/yokai-hunter/bg.jpg',
    preview: '/games/yokai-hunter/preview.png',
    screens: [
      '/games/yokai-hunter/1.jpg',
      '/games/yokai-hunter/2.jpg',
      '/games/yokai-hunter/3.jpg',
    ],
    info: {
      category: 'Slot',
      rtp: '96,20%',
      volatility: 'High',
      maxWin: 'x5000',
    },
    releaseDate: '',
  },
  {
    id: 8,
    type: 'call_of_the_jungle',
    title: ['Call of', 'the Jungle'],
    desc: 'Prepare yourself with nimbleness and courage, as you embark on a journey to hunt for treasures. Spin the wheel and claim your rewards, with the curious inhabitants of the jungle aiding your victory! Stay vigilant and alert!',
    descShort:
      'Spin the wheel and claim your rewards, with the curious inhabitants of the jungle aiding your victory!',
    img: '/games/call-of-the-jungle/hero.png',
    bg: '/games/call-of-the-jungle/bg.jpg',
    preview: '/games/call-of-the-jungle/preview.png',
    screens: [
      '/games/call-of-the-jungle/1.jpg',
      '/games/call-of-the-jungle/2.jpg',
      '/games/call-of-the-jungle/3.jpg',
    ],
    info: {
      category: 'Slot',
      rtp: '96,20%',
      volatility: 'Low',
      maxWin: 'x100',
    },
    releaseDate: 'November 2022',
  },
  {
    id: 139,
    type: 'the_red_widow',
    title: ['The Red', 'Widow'],
    desc: 'She can run, she can hide but the Detective is on her tail. Can you uncover all the clues and make her pay? Engage in this noire adventure and make fortune!',
    descShort:
      'Stylized noir adventure with two protagonists. Whoever you choose the payouts are huge!',
    img: '/games/the-red-widow/hero.png',
    bg: '/games/the-red-widow/bg.jpg',
    preview: '/games/the-red-widow/preview.png',
    screens: [
      '/games/the-red-widow/1.jpg',
      '/games/the-red-widow/2.jpg',
      '/games/the-red-widow/3.jpg',
      '/games/the-red-widow/4.jpg',
    ],
    info: {
      category: 'Slot',
      rtp: '94.05%',
      volatility: 'High',
      maxWin: 'x20550',
    },
    releaseDate: '',
  },
  {
    id: 5,
    type: 'captain_dodger',
    title: ['Captain', 'Dodger'],
    desc: 'The enemy ship is armed with cannons and carries a vast amount of gold. Select the correct cannon, loaded with coins instead of cannonballs, and claim your loot. Best of luck!',
    descShort:
      'Cannons are scattered throughout the area! Select the correct cannon, loaded with coins instead of cannonballs, and claim your loot.',
    img: '/games/captain-dodger/hero.png',
    bg: '/games/captain-dodger/bg.jpg',
    preview: '/games/captain-dodger/preview.png',
    screens: [
      '/games/captain-dodger/1.jpg',
      '/games/captain-dodger/2.jpg',
      '/games/captain-dodger/3.jpg',
      '/games/captain-dodger/4.jpg',
      '/games/captain-dodger/5.jpg',
    ],
    info: {
      category: 'Instant',
      rtp: '96,40%',
      volatility: 'Dynamic',
      maxWin: 'x10000',
    },
    releaseDate: 'November 2022',
  },
  {
    id: 13,
    type: 'castle_smashers',
    title: ['Castle', 'Smashers'],
    desc: 'In this kingdom, everyone strives to be a champion. Achieve fame and fortune by revealing three matching numbers in the fewest possible steps! Earn the admiration of the adorable heroes!',
    descShort:
      'Be a champion! Achieve fame and fortune by revealing three matching numbers in the fewest possible steps!',
    img: '/games/castle-smashers/hero.png',
    bg: '/games/castle-smashers/bg.jpg',
    preview: '/games/castle-smashers/preview.png',
    screens: [
      '/games/castle-smashers/1.jpg',
      '/games/castle-smashers/2.jpg',
      '/games/castle-smashers/3.jpg',
      '/games/castle-smashers/4.jpg',
    ],
    info: {
      category: 'Instant',
      rtp: '95,90%',
      volatility: 'High',
      maxWin: 'x1000',
    },
    releaseDate: 'November 2022',
  },
  {
    id: 7,
    type: 'chickeno',
    title: ['', 'Chickeno'],
    desc: 'Brave chicken needs your help! Search for eggs containing coins and the chick will put them in its bucket. Playing together you will become rich!',
    descShort:
      'Brave chicken needs your help! Search for eggs containing coins and the chick will put them in its bucket!',
    img: '/games/chickeno/hero.png',
    bg: '/games/chickeno/bg.jpg',
    preview: '/games/chickeno/preview.png',
    screens: ['/games/chickeno/1.jpg', '/games/chickeno/2.jpg', '/games/chickeno/3.jpg'],
    info: {
      category: 'Instant',
      rtp: '95,20%',
      volatility: 'Tunable',
      maxWin: 'x1000',
    },
    releaseDate: 'November 2022',
  },
  {
    id: 14,
    type: 'hilo',
    title: ['Grave', 'Hilo'],
    desc: 'Be&nbsp;quiet! You’re not alone in this graveyard! Attempt to predict whether the next card will have a higher or lower value. Plunder the tomb and make an escape with your prize! Be cautious, for the zombies will feast on your brain matter!',
    descShort:
      "You're not alone in this graveyard! Predict whether the next card will have a higher or lower value and escape with your prize!",
    img: '/games/grave-hilo/hero.png',
    bg: '/games/grave-hilo/bg.jpg',
    preview: '/games/grave-hilo/preview.png',
    screens: ['/games/grave-hilo/1.jpg', '/games/grave-hilo/2.jpg', '/games/grave-hilo/3.jpg'],
    info: {
      category: 'Instant',
      rtp: '95,40%',
      volatility: 'High',
      maxWin: 'x10000',
    },
    releaseDate: 'April 2023',
  },
  {
    id: 4,
    type: 'i_am_a_cat',
    title: ["I'm a", 'Cat'],
    desc: "My shaggy friend and I enjoy playing games of chance. How about you? Select three chips, achieve the winning combination and claim your prize. It's quite simple!",
    descShort:
      "Select three chips, achieve the winning combination and claim your prize. It's quite simple!",
    img: '/games/i-am-a-cat/hero.png',
    bg: '/games/i-am-a-cat/bg.jpg',
    preview: '/games/i-am-a-cat/preview.png',
    screens: [
      '/games/i-am-a-cat/1.jpg',
      '/games/i-am-a-cat/2.jpg',
      '/games/i-am-a-cat/3.jpg',
      '/games/i-am-a-cat/4.jpg',
    ],
    info: {
      category: 'Instant',
      rtp: '95,70%',
      volatility: 'Tunable',
      maxWin: 'x156',
    },
    releaseDate: 'November 2022',
  },
  {
    id: 12,
    type: 'thimbles',
    title: ['King', 'Thimbles'],
    desc: 'Challenge yourself with an age-old legendary game against our King Thimbles! They will attempt to deceive you, but if they fail, then the victory is yours! Beware, for it is not as simple as it may appear at first glance!',
    descShort:
      'Challenge yourself with a legendary game against our King Thimbles! They will try to fool you, but if they fail - the prize is yours!',
    img: '/games/king-thimbles/hero.png',
    bg: '/games/king-thimbles/bg.jpg',
    preview: '/games/king-thimbles/preview.png',
    screens: [
      '/games/king-thimbles/1.jpg',
      '/games/king-thimbles/2.jpg',
      '/games/king-thimbles/3.jpg',
    ],
    info: {
      category: 'Instant',
      rtp: '96%',
      volatility: 'Tunable',
      maxWin: 'x2,85',
    },
    releaseDate: 'November 2022',
  },
  {
    id: 10,
    type: 'monk_frogs',
    title: ['Monk', 'Frogs'],
    desc: 'The monk frogs are currently meditating in silence, but hunger will soon take over. Select one of the three monks and bet on which one will catch the mosquito first. Guess correctly and claim your well-deserved reward!',
    descShort:
      'Select one of the three monks and bet on which one will catch the mosquito first. Guess correctly and claim your well-deserved reward!',
    img: '/games/monk-frogs/hero.png',
    bg: '/games/monk-frogs/bg.jpg',
    preview: '/games/monk-frogs/preview.png',
    screens: ['/games/monk-frogs/1.jpg', '/games/monk-frogs/2.jpg', '/games/monk-frogs/3.jpg'],
    info: {
      category: 'Instant',
      rtp: '96,50%',
      volatility: 'Low',
      maxWin: 'x2,85',
    },
    releaseDate: 'November 2022',
  },
  {
    id: 3,
    type: 'piggy_bar',
    title: ['Piggy', 'Bar'],
    desc: 'Take a seat and relax. Our skilled chef will amaze you with his culinary expertise. Guess the dish concealed under the lid and get rewarded. Best of luck!',
    descShort:
      'Test your knowledge by guessing the dish concealed under the lid and see if you can outsmart our skilled chef. Best of luck!',
    img: '/games/piggy-bar/hero.png',
    bg: '/games/piggy-bar/bg.jpg',
    preview: '/games/piggy-bar/preview.png',
    screens: ['/games/piggy-bar/1.jpg', '/games/piggy-bar/2.jpg', '/games/piggy-bar/3.jpg'],
    info: {
      category: 'Instant',
      rtp: '95,80%',
      volatility: 'Tunable',
      maxWin: 'x3,8',
    },
    releaseDate: 'November 2022',
  },
  {
    id: 1,
    type: 'pirate_dice',
    title: ['Pirate', 'Dice'],
    desc: 'Greetings, aboard this pirate vessel with a hearty Yo-ho-ho! Place your bet on a number - the higher the match, the greater the payout! Prove your worth and claim the treasure!',
    descShort:
      ' Yo-ho-ho! Place your bet on a number - the higher the match, the greater the payout! ',
    img: '/games/pirate-dice/hero.png',
    bg: '/games/pirate-dice/bg.jpg',
    preview: '/games/pirate-dice/preview.png',
    screens: ['/games/pirate-dice/1.jpg', '/games/pirate-dice/2.jpg', '/games/pirate-dice/3.jpg'],
    info: {
      category: 'Instant',
      rtp: '95,50%',
      volatility: 'Low',
      maxWin: 'x4',
    },
    releaseDate: 'November 2022',
  },
  {
    id: 6,
    type: 'rock_vs_paper',
    title: ['Rock', 'vs Paper'],
    desc: 'Did you know that the game of rock-paper-scissors dates back thousands of years? Increase your satisfaction from playing the game by letting money act as a multiplier. Why not raise the stakes?',
    descShort:
      'Increase your satisfaction from playing this classic game by letting money act as a multiplier. Why not raise the stakes?',
    img: '/games/rock-vs-paper/hero.png',
    bg: '/games/rock-vs-paper/bg.jpg',
    preview: '/games/rock-vs-paper/preview.png',
    screens: [
      '/games/rock-vs-paper/1.jpg',
      '/games/rock-vs-paper/2.jpg',
      '/games/rock-vs-paper/3.jpg',
    ],
    info: {
      category: 'Instant',
      rtp: '95,30%',
      volatility: 'Low',
      maxWin: 'x1,85',
    },
    releaseDate: 'November 2022',
  },
  {
    id: 11,
    type: 'santa_little_helper',
    title: ["Santa's", 'Little Helper'],
    desc: "Santa's little helper spins the coin while you choose heads or tails. Guess correctly and claim your gold! ",
    descShort:
      "Santa's little helper spins the coin while you choose heads or tails. Guess correctly and claim your gold! ",
    img: '/games/santa-little-helper/hero.png',
    bg: '/games/santa-little-helper/bg.jpg',
    preview: '/games/santa-little-helper/preview.png',
    screens: [
      '/games/santa-little-helper/1.jpg',
      '/games/santa-little-helper/2.jpg',
      '/games/santa-little-helper/3.jpg',
    ],
    info: {
      category: 'Instant',
      rtp: '96,60%',
      volatility: 'High',
      maxWin: 'x30.4',
    },
    releaseDate: 'November 2022',
  },
  {
    id: 2,
    type: 'storm_riders',
    title: ['Storm', 'Riders'],
    desc: 'Shhh! Be quiet and cautious&nbsp;- avoid opening any boxes that may contain explosives. Each new box containing gold offers a greater reward. Sure enough, fortune is on your side, move on!',
    descShort: 'Avoid opening boxes with bombs. Each new box with gold gives you greater reward.',
    img: '/games/storm-riders/hero.png',
    bg: '/games/storm-riders/bg.jpg',
    preview: '/games/storm-riders/preview.png',
    screens: [
      '/games/storm-riders/1.jpg',
      '/games/storm-riders/2.jpg',
      '/games/storm-riders/3.jpg',
      '/games/storm-riders/4.jpg',
    ],
    info: {
      category: 'Instant',
      rtp: '95,10%',
      volatility: 'Tunable',
      maxWin: 'x4,9m',
    },
    releaseDate: 'November 2022',
  },
  {
    id: 117,
    type: 'strongman',
    title: ['', 'Strongman'],
    desc: 'Our hero is ready to prove that he is the strongest man in the world! Just look the hammer he weilds! Place a bet and when he successfully shatters the bar, you shall emerge victorious!',
    descShort:
      'Place a bet and when our hero successfully shatters the bar, you shall emerge victorious!',
    img: '/games/strongman/hero.png',
    bg: '/games/strongman/bg.jpg',
    preview: '/games/strongman/preview.png',
    screens: ['/games/strongman/1.jpg', '/games/strongman/2.jpg', '/games/strongman/3.jpg'],
    info: {
      category: 'Instant',
      rtp: '96,70%',
      volatility: 'High',
      maxWin: 'x9500',
    },
    releaseDate: '',
  },
  {
    id: 16,
    type: 'swoop',
    title: ['', 'Swoop'],
    desc: 'Join our hero on a sleek crusher as he descends into the mine to break the stones and unearth precious gems. They are truly valuable! Just take a look at their exquisite beauty.',
    descShort: 'Crush stones to acquire precious gems. They hold immense value!',
    img: '/games/swoop/hero.png',
    bg: '/games/swoop/bg.jpg',
    preview: '/games/swoop/preview.png',
    screens: [
      '/games/swoop/1.jpg',
      '/games/swoop/2.jpg',
      '/games/swoop/3.jpg',
      '/games/swoop/4.jpg',
    ],
    info: {
      category: 'Instant',
      rtp: '95,60%',
      volatility: 'Low',
      maxWin: 'x1000',
    },
    releaseDate: 'November 2022',
  },
  {
    id: 15,
    type: 'fox_job',
    title: ['The', "Fox's Job"],
    desc: 'Stock up, since we are going for a work! Rely on your intuition, sequentially open each cell in every row, but try to avoid opening handcuffs, otherwise dreams will remain dreams.',
    descShort:
      'Rely on your intuition, sequentially open each cell in every row, but try to avoid opening handcuffs.',
    img: '/games/the-fox-job/hero.png',
    bg: '/games/the-fox-job/bg.jpg',
    preview: '/games/the-fox-job/preview.png',
    screens: ['/games/the-fox-job/1.jpg', '/games/the-fox-job/2.jpg', '/games/the-fox-job/3.jpg'],
    info: {
      category: 'Instant',
      rtp: '96,30%',
      volatility: 'Low',
      maxWin: 'x3891',
    },
    releaseDate: 'April 2023',
  },
  {
    id: 118,
    type: 'wild_west_criminals',
    title: ['Wild West', 'Criminals'],
    desc: 'The Wild West is governed by treacherous criminal kingpins. Though they may appear charming at first glance, you must track them down and neutralize them to receive a lucrative bounty reward!',
    descShort:
      'The Wild West is governed by treacherous criminal kingpins. Track them down to receive a lucrative bounty reward!',
    img: '/games/wild-west-criminals/hero.png',
    bg: '/games/wild-west-criminals/bg.jpg',
    preview: '/games/wild-west-criminals/preview.png',
    screens: [
      '/games/wild-west-criminals/1.jpg',
      '/games/wild-west-criminals/2.jpg',
      '/games/wild-west-criminals/3.jpg',
    ],
    info: {
      category: 'Instant',
      rtp: '96,80%',
      volatility: 'Low',
      maxWin: 'x382',
    },
    releaseDate: '',
  },
  {
    id: 9,
    type: 'wish_of_fortune',
    title: ['Wish of', 'Fortune'],
    desc: 'Resist the alluring charms of the genie girl and put your intuition to the test! Uncover the well-hidden coins and claim your victory! This beauty is rooting for you!',
    descShort: 'Uncover the well-hidden coins and claim your victory!',
    img: '/games/wish-of-fortune/hero.png',
    bg: '/games/wish-of-fortune/bg.jpg',
    preview: '/games/wish-of-fortune/preview.png',
    screens: [
      '/games/wish-of-fortune/1.jpg',
      '/games/wish-of-fortune/2.jpg',
      '/games/wish-of-fortune/3.jpg',
    ],
    info: {
      category: 'Instant',
      rtp: '96,10%',
      volatility: 'High',
      maxWin: 'x10150',
    },
    releaseDate: 'November 2022',
  },
  {
    id: 119,
    type: 'angel_vs_demon',
    title: ['Angel', 'vs Demon'],
    desc: 'A grand performance is scheduled to take place at the Paradise Theater of Angels. However, the Demon has his own agenda and intends to put on his own play. A struggle for the stage ensues, and the ultimate winner, with access to bonus games, is in your hands!',
    descShort:
      'A struggle for the stage ensues, and the ultimate winner, with access to bonus games, is in your hands!',
    img: '/games/angel-vs-demon/hero.png',
    bg: '/games/angel-vs-demon/bg.jpg',
    preview: '/games/angel-vs-demon/preview.png',
    screens: [
      '/games/angel-vs-demon/1.jpg',
      '/games/angel-vs-demon/2.jpg',
      '/games/angel-vs-demon/3.jpg',
    ],
    info: {
      category: 'Slot',
      rtp: '97,70%',
      volatility: 'Medium',
      maxWin: 'x4000',
    },
    releaseDate: '',
  },
  {
    id: 121,
    type: 'crash',
    title: ['', 'Crash'],
    desc: 'Somewhere in the Wild West, our gambling hero is prepared to loot anything with ample riches. Assist him in achieving the perfect elevation to boost your overall winnings!',
    descShort:
      'Help our gambling hero in reaching optimal height to rob the wealthiest bank! Life in the Wild West is always thrilling!',
    img: '/games/crash/hero.png',
    bg: '/games/crash/bg.jpg',
    preview: '/games/crash/preview.png',
    screens: [
      '/games/crash/1.jpg',
      '/games/crash/2.jpg',
      '/games/crash/3.jpg',
      '/games/crash/4.jpg',
    ],
    info: {
      category: 'Instant',
      rtp: '95,79%',
      volatility: 'High',
      maxWin: 'x1000',
    },
    releaseDate: '',
  },
  {
    id: 122,
    type: 'crazy_warriors',
    title: ['Crazy', 'Warriors'],
    desc: 'Although our warriors may be small in size, do not underestimate their strength! And to maintain strength, it is essential to consume proper nourishment. Join the feast of big tasty wins!',
    descShort:
      'Although our warriors may be small in size, do not underestimate their strength! Join the feast of big tasty wins!',
    img: '/games/crazy-warriors/hero.png',
    bg: '/games/crazy-warriors/bg.jpg',
    preview: '/games/crazy-warriors/preview.png',
    screens: [
      '/games/crazy-warriors/1.jpg',
      '/games/crazy-warriors/2.jpg',
      '/games/crazy-warriors/3.jpg',
    ],
    info: {
      category: 'Slot',
      rtp: '98,20%',
      volatility: 'Low',
      maxWin: 'x2750',
    },
    releaseDate: '',
  },
  {
    id: 124,
    type: 'fortune_teller',
    title: ['Fortune', 'Teller'],
    desc: 'The clairvoyant has arranged the cards before her and is prepared to engage with you. What does destiny hold in store for you? Gather three Suns and receive ten complimentary games with a Special Expanding Symbol.',
    descShort:
      'What does destiny hold in store for you? Collect 10 free games and fate your future!',
    img: '/games/fortune-teller/hero.png',
    bg: '/games/fortune-teller/bg.jpg',
    preview: '/games/fortune-teller/preview.png',
    screens: [
      '/games/fortune-teller/1.jpg',
      '/games/fortune-teller/2.jpg',
      '/games/fortune-teller/3.jpg',
    ],
    info: {
      category: 'Slot',
      rtp: '97,90%',
      volatility: 'Medium',
      maxWin: 'x5000',
    },
    releaseDate: '',
  },
  {
    id: 125,
    type: 'four_queens',
    title: ['Four', 'Queens'],
    desc: 'Introducing the Queens of the Underground - they exude coolness, strength, and power. They offer you a choice of free spins, each with its own unique number of spins and multipliers. Select your favorite Queen and prepare to reap the rewards!',
    descShort:
      'Introducing the Queens of the Underground. Select your favorite one and prepare to reap the rewards!',
    img: '/games/four-queens/hero.png',
    bg: '/games/four-queens/bg.jpg',
    preview: '/games/four-queens/preview.png',
    screens: [
      '/games/four-queens/1.jpg',
      '/games/four-queens/2.jpg',
      '/games/four-queens/3.jpg',
      '/games/four-queens/4.jpg',
    ],
    info: {
      category: 'Slot',
      rtp: '97,40%',
      volatility: 'Medium',
      maxWin: 'x5000',
    },
    releaseDate: '',
  },
  {
    id: 127,
    type: 'irish_magic',
    title: ['Irish', 'Magic'],
    desc: 'Leprechauns are known for their generosity and this one is no exception - he would love to share his wealth with you! However, he also enjoys a good game. While you try your luck at a thimble game with him, your free spins will keep rolling in!',
    descShort:
      'While you are winning at a thimble game with this leprechaun, your free spins will keep rolling in!',
    img: '/games/irish-magic/hero.png',
    bg: '/games/irish-magic/bg.jpg',
    preview: '/games/irish-magic/preview.png',
    screens: ['/games/irish-magic/1.jpg', '/games/irish-magic/2.jpg', '/games/irish-magic/3.jpg'],
    info: {
      category: 'Slot',
      rtp: '97,10%',
      volatility: 'Medium',
      maxWin: 'x5000',
    },
    releaseDate: '',
  },
  {
    id: 129,
    type: 'lines_of_life',
    title: ['Lines', 'of Life'],
    desc: 'A deadly virus is spreading around the world! Zombies are coming! Hurry! Get to the secure bunker, defend yourself, grab 10 free spins and get the loot from the killed zombies!',
    descShort:
      'Zombies are coming! Hurry! Get to the secure bunker, defend yourself, grab 10 free spins!',
    img: '/games/lines-of-life/hero.png',
    bg: '/games/lines-of-life/bg.jpg',
    preview: '/games/lines-of-life/preview.png',
    screens: [
      '/games/lines-of-life/1.jpg',
      '/games/lines-of-life/2.jpg',
      '/games/lines-of-life/3.jpg',
    ],
    info: {
      category: 'Slot',
      rtp: '97%',
      volatility: 'Medium',
      maxWin: 'x5000',
    },
    releaseDate: '',
  },
  {
    id: 131,
    type: 'saturn_express',
    title: ['Saturn', 'Express'],
    desc: "Welcome aboard the spaceship! Our brave astronaut has gathered a collection of unique alien life forms. Are they docile or should we approach them with caution? Let's explore further in the bonus games, where a random number of free spins await you!",
    descShort:
      'Our brave astronaut has gathered a collection of unique alien life forms. Are they docile or should we approach them with caution?',
    img: '/games/saturn-express/hero.png',
    bg: '/games/saturn-express/bg.jpg',
    preview: '/games/saturn-express/preview.png',
    screens: [
      '/games/saturn-express/1.jpg',
      '/games/saturn-express/2.jpg',
      '/games/saturn-express/3.jpg',
    ],
    info: {
      category: 'Slot',
      rtp: '97,20%',
      volatility: 'Low',
      maxWin: 'x2000',
    },
    releaseDate: '',
  },
  {
    id: 132,
    type: 'slots_creek',
    title: ['Slots', 'Creek'],
    desc: "Welcome to Slots Creek, where the sun always shines and nothing happens. However, today is different! Head over to Stan's Geek Store and try your hand at the new arcade machine. Will you be the one to set the high score and win 11 free games with fixed wilds?",
    descShort:
      "The Stan's Geek Store has just installed a new arcade machine! Can you beat the high score and win 11 complimentary games?",
    img: '/games/slots-creek/hero.png',
    bg: '/games/slots-creek/bg.jpg',
    preview: '/games/slots-creek/preview.png',
    screens: [
      '/games/slots-creek/1.jpg',
      '/games/slots-creek/2.jpg',
      '/games/slots-creek/3.jpg',
      '/games/slots-creek/4.jpg',
      '/games/slots-creek/5.jpg',
      '/games/slots-creek/6.jpg',
    ],
    info: {
      category: 'Slot',
      rtp: '97,50%',
      volatility: 'Low',
      maxWin: 'x1000',
    },
    releaseDate: '',
  },
  {
    id: 133,
    type: 'spin_guru',
    title: ['Spin', 'Guru'],
    desc: 'What a delightful experience it is to meditate by the waterfall in the morning. However, what is this commotion that I hear? An irritating monkey is disturbing my peaceful state with its noise. Could I use this disturbance to achieve Zen and gain bonus games?',
    descShort:
      'An irritating monkey is disturbing my peaceful state with its noise. Could I use this disturbance to achieve Zen and gain bonus games?',
    img: '/games/spin-guru/hero.png',
    bg: '/games/spin-guru/bg.jpg',
    preview: '/games/spin-guru/preview.png',
    screens: [
      '/games/spin-guru/1.jpg',
      '/games/spin-guru/2.jpg',
      '/games/spin-guru/3.jpg',
      '/games/spin-guru/4.jpg',
    ],
    info: {
      category: 'Slot',
      rtp: '97,80%',
      volatility: 'High',
      maxWin: 'x9000',
    },
    releaseDate: '',
  },
  {
    id: 134,
    type: 'witching_hour',
    title: ['Witching', 'Hour'],
    desc: 'A charming witch has invited you to assist her in brewing a magical potion. In return for your help, she promises to bestow upon you a spell of good fortune. During the bonus games, collect ingredients to unlock an increasing number of free spins!',
    descShort:
      'A charming witch promises to bestow upon you a spell of good fortune. Collect ingredients to unlock an increasing number of free spins!',
    img: '/games/witching-hour/hero.png',
    bg: '/games/witching-hour/bg.jpg',
    preview: '/games/witching-hour/preview.png',
    screens: [
      '/games/witching-hour/1.jpg',
      '/games/witching-hour/2.jpg',
      '/games/witching-hour/3.jpg',
      '/games/witching-hour/4.jpg',
      '/games/witching-hour/5.jpg',
      '/games/witching-hour/6.jpg',
    ],
    info: {
      category: 'Slot',
      rtp: '97,30%',
      volatility: 'High',
      maxWin: 'x10000',
    },
    releaseDate: '',
  },
  {
    id: 138,
    type: 'freak_show',
    title: ['Freak', 'Show'],
    desc: 'The show is weird and bountiful! Unique characters with high payouts and high volatility to make you breathless when the spin lands in your favor! This Show Must Go On!',
    descShort:
      'Weirdos and freaks are rolling before your eyes. Witness as they make you wealthy while providing entertainment!',
    img: '/games/freak-show/hero.png',
    bg: '/games/freak-show/bg.jpg',
    preview: '/games/freak-show/preview.png',
    screens: [
      '/games/freak-show/1.jpg',
      '/games/freak-show/2.jpg',
      '/games/freak-show/3.jpg',
      '/games/freak-show/4.jpg',
    ],
    info: {
      category: 'Slot',
      rtp: '97,40%',
      volatility: 'High',
      maxWin: 'x5000',
    },
    releaseDate: '',
  },
];
