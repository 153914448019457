<template>
  <div class="header">
    <div class="blur-ball"></div>
    <div class="header-content container">
      <div class="header-games"></div>
      <router-link class="header-logo" to="/">
        <Logo />
      </router-link>
      <a class="header-email" href="mailto:info@pixmove.com">
        <span>info@pixmove.com</span>
        <img src="@/assets/icons/mail.svg" alt="" />
      </a>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue';

export default {
  name: 'Header',
  components: { Logo },
  props: {
    withoutBtn: { type: Boolean, default: false },
  },
};
</script>

<style scoped lang="scss">
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 160px;
  z-index: 5;
}

.blur-ball {
  position: absolute;
  left: -50%;
  bottom: 0;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    ellipse closest-corner at 50% 50%,
    rgba(0, 0, 0, 1) 25%,
    rgba(17, 17, 17, 0.1)
  );
  filter: blur(24px);
  z-index: -1;
  border-radius: 80%;
}

.header-content {
  height: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}

.header-all-icon {
  display: none;

  img {
    width: 32px;
    height: 32px;
  }
}

.header-logo {
  justify-self: center;
}

.header-email {
  justify-self: end;

  span {
    font-weight: 700;
    font-size: 28px;
    color: var(--primary);
    text-shadow: 4px 4px 5px #000000;
  }

  img {
    width: 32px;
    height: 32px;
    display: none;
  }
}

@media (max-width: 900px) {
  .header {
    height: auto;
    padding: 20px 0;
  }

  .header-all-icon {
    display: block;
  }

  .header-all-btn {
    display: none !important;
  }

  .header-email {
    span {
      display: none;
    }

    img {
      display: block;
    }
  }
}

@media (max-width: 600px) {
  .header {
    padding: 16px 0;
  }

  .header-email {
    font-size: 16px;
  }

  .blur-ball {
    bottom: -20px;
    height: 300%;
  }
}
</style>
