<template>
  <GameModal />
  <img class="app-background" src="@/assets/images/background.png" />
  <router-view class="view" />
</template>

<script>
import GameModal from '@/components/GameModal.vue';

export default {
  components: { GameModal },
  computed: {
    currentRoute() {
      return this.$route.fullPath;
    },
  },
  watch: {
    currentRoute() {
      document.querySelector('#app').scrollTo({ top: 0 });
    },
  },
};
</script>

<style lang="scss">
// fonts

@font-face {
  font-family: 'Podkova';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('@/assets/fonts/podkova/normal.woff2') format('woff2');
}

@font-face {
  font-family: 'Podkova';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('@/assets/fonts/podkova/bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Floripa';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('@/assets/fonts/floripa/normal.woff2') format('woff2');
}

// colors

:root {
  --primary: #fcdda0;
  --primary-2: #ff852f;
  --primary-3: #009898;
  --primary-4: #3c1b07;
}

// styles

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
}

html {
  display: block;
  width: 100%;
  height: 100%;
  //overflow: hidden;
}

body {
  display: block;
  width: 100%;
  height: 100%;
  background: black;
  color: white;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Podkova', sans-serif;
}

input,
textarea,
select,
button,
a {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  text-decoration: none;
  background: none;
  border: none;
  outline: none;
}

button:not(:disabled) {
  cursor: pointer;
}

img {
  display: block;
  outline-style: none;
  user-select: none;
  -webkit-user-drag: none;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}
*::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 6px;
}

*::-webkit-scrollbar-track {
  border-radius: 6px;
}

#app {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.app-background {
  position: fixed;
  z-index: -3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.view {
  position: relative;
  z-index: 1;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  overflow: hidden;
}

h1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  span {
    font-family: 'Floripa', sans-serif;
    font-weight: 400;
    font-size: 64px;
    line-height: 80%;
    color: var(--primary);

    &:last-child {
      color: var(--primary-2);
      text-shadow: 0px 0px 25px #000000;
    }
  }

  &.large span {
    font-size: 100px;
  }
}

.container {
  width: 90vw;
  max-width: 1300px;
  margin: 0 auto;
}

@media (max-width: 1560px) {
  h1.large span {
    font-size: 80px;
  }
}

@media (max-width: 1200px) {
  h1.large span,
  h1 span {
    font-size: 60px;
  }
}

@media (max-width: 900px) {
  h1.large span,
  h1 span {
    font-size: 40px;
  }
}

@media (max-width: 600px) {
  h1.large span,
  h1 span {
    font-size: 28px;
  }
}

@media (min-width: 2000px) {
  body {
    zoom: 1.2;
  }
}
</style>
