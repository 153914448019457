<template>
  <router-link class="all-btn" :class="type" to="/games">
    <svg
      v-if="type === 'back'"
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.41449 0.333252L0.666626 6.99992L5.41449 13.6666L7.99996 12.2251L4.28628 6.99992L7.99996 1.77469L5.41449 0.333252Z"
      />
    </svg>
    <div class="all-btn-text">All games</div>
  </router-link>
</template>

<script>
export default {
  name: 'AllButton',
  props: {
    type: { type: String, default: 'default' }, // default, back
  },
};
</script>

<style scoped lang="scss">
.all-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 8px;
  border-radius: 20px;
  text-transform: uppercase;
  line-height: 100%;
  font-size: 20px;
  font-weight: 600;
  border: 2px solid var(--primary);
  color: var(--primary);
  width: 200px;
  transition: all 0.3s ease;
}

.all-btn.default:hover {
  background: var(--primary);
  color: var(--primary-4);
}

.all-btn.back {
  svg {
    margin-right: 12px;
    fill: currentColor;
    transition: transform 0.3s ease;
  }
}

.all-btn.back:hover {
  color: white;

  svg {
    transform: translateX(-8px);
  }
}

@media (max-width: 600px) {
  .all-btn {
    width: 140px;
    font-size: 12px;
  }
}
</style>
