<template>
  <div class="game-view">
    <Header />
    <GamesSelect />
    <div class="games-detail-gradient"></div>
    <transition>
      <div
        class="game-detail-bg"
        :key="currentGame.id"
        :style="{ 'background-image': `url(${currentGame.bg})` }"
      />
    </transition>
    <div class="game-detail-wrap container">
      <transition>
        <div class="game-detail" :key="currentGame.id">
          <h1 class="game-detail-title">
            <span>{{ currentGame.title[0] }}</span>
            <span>{{ currentGame.title[1] }}</span>
          </h1>
          <div class="game-detail-demo-labels">
            <DemoButton :id="currentGame.id" class="game-detail-demo" />
            <div class="game-detail-demo-fixed">
              <DemoButton :id="currentGame.id" :use-icon="false" />
            </div>
            <div class="game-detail-labels">
              <div>
                Category: <span>{{ currentGame.info.category }}</span>
              </div>
              <div>
                RTP: <span>{{ currentGame.info.rtp }}</span>
              </div>
              <div>
                Max win: <span>{{ currentGame.info.maxWin }}</span>
              </div>
              <div>
                Volatility: <span>{{ currentGame.info.volatility }}</span>
              </div>
            </div>
          </div>
          <p class="game-detail-desc" v-html="currentGame.desc"></p>
          <div class="game-detail-info">
            <div class="game-detail-info-block">
              <div class="game-detail-info-title">Languages:</div>
              <div class="game-detail-info-lang">
                <img src="@/assets/icons/uk.svg" alt="" />
                <span>and any other <br />language</span>
              </div>
            </div>
            <div class="game-detail-info-block">
              <div class="game-detail-info-title">Adaptive:</div>
              <div class="game-detail-info-adaptive">
                <div><img src="@/assets/icons/desktop.svg" alt="" /></div>
                <div><img src="@/assets/icons/mobile.svg" alt="" /></div>
                <div><img src="@/assets/icons/mobile-horizontal.svg" alt="" /></div>
              </div>
            </div>
            <div v-if="currentGame.releaseDate" class="game-detail-info-block">
              <div class="game-detail-info-title">Release date:</div>
              <div class="game-detail-info-release">
                {{ currentGame.releaseDate }}
              </div>
            </div>
          </div>
          <ScreenshotsSlider class="game-detail-slider" :images="currentGame.screens" />
          <img :src="currentGame.img" alt="" class="game-detail-img" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import DemoButton from '@/components/DemoButton.vue';
import ScreenshotsSlider from '@/components/ScreenshotsSlider.vue';
import GamesSelect from '@/components/GamesSelect.vue';

function getCurrentGame(vm, route) {
  if (!route) route = vm.$route;
  const games = vm.$store.state.games;
  const gameType = route.params.type;
  return games.find((game) => game.type === gameType);
}

export default {
  name: 'GameView',
  components: { Header, DemoButton, ScreenshotsSlider, GamesSelect },
  beforeRouteUpdate(to) {
    this.currentGame = getCurrentGame(this, to);
  },
  data(vm) {
    return { currentGame: getCurrentGame(vm) };
  },
};
</script>

<style lang="scss" scoped>
:deep(.header-all-btn) {
  display: none;
}

.game-view {
  padding: 20vh 0 20px;
}

.games-detail-gradient {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.01) 80%);
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.game-detail-bg {
  position: fixed;
  z-index: -2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-blend-mode: overlay;
  transition: 0.6s ease;

  &.v-enter-from,
  &.v-leave-to {
    opacity: 0;
  }
}

.game-detail-wrap {
  position: relative;
}

.game-detail {
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;
  padding: 0 0 0 80px;
  width: 100%;
  transition: 0.6s ease;

  &.v-enter-active {
    position: absolute;
  }

  &.v-enter-from,
  &.v-leave-to {
    opacity: 0;
  }
}

.game-detail-title {
  position: relative;
  z-index: -1;
  height: 102px;
  margin: 0 0 20px 0;
}

.game-detail-demo-labels {
  display: flex;
  align-items: flex-start;
  margin: 0 0 32px 0;
}

.game-detail-demo-fixed {
  display: none;
}

.game-detail-labels {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
  margin: -4px 0 -4px 32px;

  div {
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    padding: 8px 16px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    margin: 4px 12px 4px 0;
    backdrop-filter: blur(4px);

    span {
      color: var(--primary);
    }
  }
}

.game-detail-desc,
.game-detail-info {
  padding: 0 0 0 50px;
}

.game-detail-desc {
  min-height: 200px;
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  width: 58%;
  text-shadow: 4px 4px 10px #000000;
  margin: 0 0 32px 0;
}

.game-detail-info {
  display: flex;
  margin: 0 0 40px 0;
}

.game-detail-info-block {
  margin: 0 48px 0 0;
}

.game-detail-info-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  margin: 0 0 8px 0;
}

.game-detail-info-lang {
  display: flex;
  height: 44px;
  align-items: center;
  padding: 0 16px 0 8px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 100px;

  img {
    width: 28px;
    height: 28px;
    flex-shrink: 0;
    margin: 0 14px 0 0;
  }
  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 90%;
    color: var(--primary);
  }
}

.game-detail-info-adaptive {
  display: flex;

  div {
    width: 44px;
    height: 44px;
    flex-shrink: 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 200px;
    padding: 8px;

    & + div {
      margin: 0 0 0 12px;
    }
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.game-detail-info-release {
  padding: 12px 16px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  color: var(--primary);
}

.game-detail-img {
  position: absolute;
  z-index: -1;
  right: -25%;
  top: 3%;
  width: 90%;
}

@media (max-width: 1800px) {
  .game-view {
    padding: 20vh 0 50px;
  }

  .game-detail-wrap {
    margin: 0 10vw 0 auto;
  }
}

@media (max-width: 1700px) {
  .game-detail-wrap {
    margin: 0 4vw 0 auto;
  }
}

@media (max-width: 1560px) {
  :deep(.header-all-btn) {
    display: flex;
  }

  .game-detail-title {
    height: auto;
  }

  .game-detail-wrap {
    margin: 0 auto;
  }

  .game-detail {
    padding: 0;
  }

  .games-detail-gradient {
    background: rgba(0, 0, 0, 0.4);
  }
}

@media (max-width: 1400px) {
  .game-detail-img {
    top: 4vw;
  }
}

@media (max-width: 1200px) {
  .game-detail-img {
    top: 6vw;
  }
}

@media (max-width: 1050px) {
  .game-detail-img {
    top: 12vw;
  }
}

@media (max-width: 900px) {
  .game-view {
    padding: 120px 0;
  }

  .game-detail {
    display: flex;
    flex-direction: column;
  }

  .game-detail-demo {
    display: none;
  }

  .game-detail-demo-fixed {
    display: flex;
    position: fixed;
    z-index: 3;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 32px 5vw;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));

    :deep(.demo-button) {
      width: 100%;
    }
  }

  .game-detail-labels {
    margin: 0;
  }

  .game-detail-img {
    width: 100%;
    top: calc(500px - 100vw / 2);
    filter: brightness(0.65);
  }

  .game-detail-desc {
    font-size: 24px;
    margin-bottom: 80px;
    margin-left: 20px;
    padding-left: 0;
  }

  .game-detail-info {
    flex-wrap: wrap;
    margin: 24px 0 0 0;
    padding-left: 0;
    order: 5;
  }

  .game-detail-info-block {
    margin: 0 48px 16px 0;
  }
}

@media (max-width: 600px) {
  .game-view {
    padding: 100px 0;
  }

  .game-detail-desc {
    font-size: 20px;
    width: 60%;
  }

  .game-detail-demo-labels {
    overflow: hidden;
    margin: 0 -5vw 32px;
  }

  .game-detail-labels {
    flex-wrap: nowrap;
    overflow: auto;
    margin-bottom: -20px;
    padding-bottom: 20px;

    &::before,
    &:after {
      content: '';
      display: block;
      width: 5vw;
      height: 1px;
      flex-shrink: 0;
    }

    div {
      flex-shrink: 0;
      font-size: 16px;
    }
  }

  .game-detail-img {
    width: 150%;
    top: 120px;
    right: -60%;
  }

  .game-detail-info-title {
    font-size: 20px;
  }

  .game-detail-info-lang br {
    display: none;
  }
}

@media (max-width: 420px) {
  .game-detail-desc {
    font-size: 18px;
    margin-bottom: 40px;
  }

  .game-detail-demo-labels {
    margin: 0 -5vw 16px;
  }

  .game-detail-title {
    margin-bottom: 16px;
  }

  .game-detail-demo-fixed {
    padding: 24px 5vw;
  }
}

@media (min-width: 2000px) {
  .game-view {
    padding: 18vh 0 20px;
  }
}
</style>
