<template>
  <div class="footer">
    <div class="footer-content">
      <div class="footer-info">
        <div class="footer-text footer-text-primary">RNG algorythms are certified by</div>
        <a href="/license.pdf" target="_blank">
          <img src="@/assets/images/logoGA.png" alt="" class="ga-icon" />
        </a>
        <div class="footer-text footer-text-secondary">
          <span class="footer-text-bold">©&nbsp;{{ new Date().getFullYear() }}</span>
          Pixmove&nbsp;B.V.&nbsp;Heelsumstraat&nbsp;51
          E‑commerce&nbsp;Park&nbsp;Vredenberg&nbsp;Curaçao
        </div>
      </div>
      <div class="footer-info right">
        <img src="@/assets/icons/age.svg" alt="" class="age-icon" />
        <div class="footer-text footer-text-secondary footer-text-bold footer-info-site-m">
          pixmove.com&nbsp;©&nbsp;{{ new Date().getFullYear() }}
        </div>
      </div>
    </div>
    <div class="footer-bottom-s footer-text footer-text-secondary footer-text-bold">
      pixmove.com&nbsp;©&nbsp;{{ new Date().getFullYear() }}
    </div>
  </div>
</template>

<script>
export default { name: 'Footer' };
</script>

<style scoped lang="scss">
.footer {
  background: rgba(255, 255, 255, 0.03);
  width: 100%;
  margin-top: auto;
  padding: 10px;
}

.footer-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (min-width: 768px) {
    width: 90vw;
    max-width: 1300px;
    margin: 0 auto;
  }
}

.footer-info {
  display: flex;
  flex-direction: column;
}
.footer-info.right {
  align-items: flex-end;
  margin-left: 16px;
}
.footer-info > * + * {
  margin-top: 6px;
}
.footer-info-site-m {
  display: none !important;
  @media (min-width: 768px) {
    margin-top: auto;
    display: block !important;
  }
}
.footer-bottom-s {
  display: block !important;
  margin-top: 12px;
  width: 100%;
  text-align: center;
  @media (min-width: 768px) {
    margin-top: auto;
    display: none !important;
  }
}

.footer-text {
  font-size: 14px;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.36;
  @media (min-width: 768px) {
    font-size: 20px;
  }
}

.footer-text-bold {
  font-weight: 700;
}

.footer-text-primary {
  color: var(--primary-3);
}

.footer-text-secondary {
  color: #656565;
}

.ga-icon {
  height: 31px;
  width: 150px;
  @media (min-width: 768px) {
    height: 41px;
    width: 200px;
  }
}
.age-icon {
  width: 46px;
  height: 46px;
}
</style>
