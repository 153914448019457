<template>
  <div class="screenshots-slider-wrap">
    <button class="screenshots-btn prev" @click="index--" :disabled="disabledPrev">
      <img src="@/assets/icons/arrow-left.svg" alt="" />
    </button>
    <button class="screenshots-btn next" @click="index++" :disabled="disabledNext">
      <img src="@/assets/icons/arrow-right.svg" alt="" />
    </button>
    <div class="screenshots-slider">
      <div class="screenshots-slider-track" :style="{ transform: `translateX(-${index * 25}%)` }">
        <div v-for="(img, i) in images" :key="i" class="screenshots-slide">
          <img :src="img" alt="" @click="openedIndex = i" />
        </div>
      </div>
    </div>
    <teleport to="body">
      <transition>
        <div v-if="openedIndex !== null" class="screenshots-detail" @click="openedIndex = null">
          <button class="screenshots-detail-close">
            <img src="@/assets/icons/close.svg" alt="" />
          </button>
          <img class="screenshots-detail-img" :src="images[openedIndex]" alt="" @click.stop />
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script>
export default {
  props: {
    images: { type: Array, required: true },
  },
  data() {
    return {
      index: 0,
      openedIndex: null,
    };
  },
  computed: {
    disabledPrev() {
      return !this.images[this.index - 1];
    },
    disabledNext() {
      return !this.images[this.index + 4];
    },
  },
};
</script>

<style lang="scss" scoped>
.screenshots-slider-wrap {
  position: relative;
  width: 100%;
  padding: 0 50px;
}

.screenshots-slider {
  width: 100%;
  overflow: hidden;
  -webkit-user-select: none;
  user-select: none;
}

.screenshots-slider-track {
  display: flex;
  margin: 0 -16px;
  transition: 0.6s ease;
}

.screenshots-slide {
  flex-shrink: 0;
  width: 25%;
  padding: 0 16px;

  img {
    width: 100%;
    height: 165px;
    object-fit: cover;
    cursor: pointer;
  }
}

.screenshots-btn {
  position: absolute;
  top: calc(50% - 24px);
  left: -20px;
  opacity: 0.4;
  transition: 0.3s ease;

  &.next {
    left: unset;
    right: -20px;
  }

  img {
    width: 48px;
    height: 48px;
  }

  &:hover {
    opacity: 1;
  }

  &:disabled {
    opacity: 0;
    pointer-events: none;
  }
}

.screenshots-detail {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(6px);
  transition: 0.3s ease;

  &.v-enter-from,
  &.v-leave-to {
    opacity: 0;
  }
}

.screenshots-detail-img {
  max-width: 100%;
  max-height: 100%;
  transition: 0.3s ease;
}

.screenshots-detail.v-enter-from,
.screenshots-detail.v-leave-to {
  .screenshots-detail-img {
    transform: scale(0.3);
  }
}

.screenshots-detail-close {
  position: absolute;
  z-index: 1;
  right: 50px;
  top: 50px;

  img {
    width: 48px;
    height: 48px;
    opacity: 0.4;
    transition: 0.3s ease;
  }

  &:hover img {
    opacity: 1;
  }
}

@media (max-width: 1550px) {
  .screenshots-slide img {
    height: 12vw;
  }
}

@media (max-width: 900px) {
  .screenshots-btn {
    display: none;
  }

  .screenshots-slider-wrap {
    margin: 0 -5vw;
    width: 100vw;
    padding: 0;
    overflow: hidden;
  }

  .screenshots-slider {
    overflow: auto;
    padding-bottom: 20px;
    margin-bottom: -20px;
  }

  .screenshots-slider-track {
    margin: 0;

    &::before,
    &:after {
      content: '';
      display: block;
      width: 5vw;
      height: 1px;
      flex-shrink: 0;
    }
  }

  .screenshots-slide {
    width: auto;
    padding: 0;

    &:not(:last-child) {
      margin-right: 16px;
    }

    img {
      height: 122px;
      width: 200px;
    }
  }

  .screenshots-detail {
    padding: 0;
  }

  .screenshots-detail-close {
    right: 20px;
    top: 20px;
    padding: 4px;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(2px);

    img {
      width: 32px;
      height: 32px;
    }
  }
}
</style>
