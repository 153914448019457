<template>
  <div class="games-select" :class="{ block }">
    <div ref="games" class="games">
      <div
        v-for="(game, i) in games"
        :key="game.id"
        class="game-preview"
        :class="{ active: game.type === $route.params.type }"
      >
        <router-link :to="`/games/${game.type}`" @click="changeGame(game.type, i)">
          <img :src="game.preview" alt="" />
        </router-link>
      </div>
    </div>
    <AllButton type="back" class="all" />
  </div>
</template>

<script>
import AllButton from '@/components/AllButton.vue';

export default {
  components: { AllButton },
  data(vm) {
    const allGames = vm.$store.state.games;

    let games = [];
    let i = allGames.findIndex((g) => g.type === vm.$route.params.type) - 1;
    if (i < 0) i = allGames.length - 1;

    for (let j = 0; j < 6; j++) {
      games.push(allGames[i++ % allGames.length]);
    }

    return { games, block: false };
  },
  methods: {
    changeGame(gameType, index) {
      if (gameType === this.$route.params.type) return;

      this.block = true;

      const allGames = this.$store.state.games;

      if (index) {
        const lastGame = this.games[this.games.length - 1];

        let i = allGames.indexOf(lastGame);
        for (let j = 0; j < index - 1; j++) {
          this.games.push(allGames[++i % allGames.length]);
          this.$refs.games.children[j].style.opacity = 0;
        }

        this.$refs.games.style.transition = '0.6s ease';
        this.$refs.games.style.transform = `translateY(-${(index - 1) * 16}%)`;

        setTimeout(() => {
          this.$refs.games.style.transition = '';
          this.$refs.games.style.transform = '';
          this.games.splice(0, index - 1);

          this.block = false;
        }, 600);
      } else {
        const gameIndex = allGames.indexOf(this.games[0]);
        const newGame = allGames[gameIndex - 1] || allGames[allGames.length - 1];
        this.games.unshift(newGame);

        this.$refs.games.children[5].style.opacity = 0;
        this.$refs.games.style.transform = 'translateY(-16%)';

        setTimeout(() => {
          this.$refs.games.style.transition = '0.6s ease';
          this.$refs.games.style.transform = '';
        });

        setTimeout(() => {
          this.games.pop();
          this.$refs.games.style.transition = '';

          this.block = false;
        }, 600);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.games-select {
  position: fixed;
  z-index: 8;
  top: 0;
  left: 50px;
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 2vh 0 3vh;

  &.block {
    pointer-events: none;
  }
}

.games {
  height: 100%;
  min-height: 0;
}

.all {
  margin: 10px 0 0 8px;
}

.game-preview {
  display: flex;
  align-items: center;
  padding: 2px 0;
  height: 16%;
  overflow: hidden;
  transition: 0.3s ease;
  animation: fade-in 0.3s ease;

  a {
    height: 100%;
    border-radius: 32px;
  }

  img {
    width: 11vw;
    max-height: 100%;
    object-fit: cover;
    padding: 4px;
    border: 4px solid transparent;
    border-radius: 32px;
    opacity: 0.5;
    transition: 0.3s ease;
  }

  a:hover img {
    opacity: 0.8;
  }

  &.active {
    height: 20%;

    a img {
      width: 14vw;
      border-color: white;
      opacity: 1;
    }
  }
}

@media (max-width: 1800px) {
  .games-select {
    left: 24px;
  }
}

@media (max-width: 1560px) {
  .games-select {
    display: none;
  }
}
</style>
