<template>
  <router-link class="game" :to="`/games/${type}`">
    <div class="game-img">
      <img :src="preview" alt="game img" loading="lazy" class="preview" />
      <img :src="screen" alt="game img" loading="lazy" class="screenshot" />
    </div>
    <div class="game-title">{{ title[0] }} {{ title[1] }}</div>
    <div class="game-description" v-html="desc"></div>
    <div class="game-info">
      <div class="game-info-block">
        <div class="game-info-value">{{ category }}</div>
        <div class="game-info-name">Category</div>
      </div>
      <div class="game-info-block">
        <div class="game-info-value">{{ maxWin }}</div>
        <div class="game-info-name">Max win</div>
      </div>
      <div class="game-info-block">
        <div class="game-info-value">{{ volatility }}</div>
        <div class="game-info-name">Volatility</div>
      </div>
    </div>
    <DemoButton class="game-demo" :id="id" :use-icon="false" />
  </router-link>
</template>

<script>
import DemoButton from '@/components/DemoButton.vue';

export default {
  name: 'GameCard',
  components: { DemoButton },
  props: {
    id: { type: Number, required: true },
    type: { type: String, required: true },
    preview: { type: String, required: true },
    screen: { type: String, required: true },
    title: { type: Array, required: true },
    desc: { type: String, required: true },
    volatility: { type: String, required: true },
    maxWin: { type: String, required: true },
    category: { type: String, required: true },
  },
};
</script>

<style lang="scss" scoped>
.game {
  display: flex;
  flex-direction: column;
  padding: 16px;
  max-width: 414px;
  transition: all 0.3s ease;
  line-height: 120%;
  font-size: 20px;
  border-radius: 40px;
  position: relative;
}

.game > * + * {
  margin-top: 8px;
  text-align: center;
}

.game-demo {
  position: absolute;
  bottom: 16px;
  left: 16px;
  width: calc(100% - 32px);
  opacity: 0;
}

.game-img {
  position: relative;
  width: 100%;
  overflow: hidden;

  img {
    aspect-ratio: 16/10;
    object-fit: cover;
    width: 100%;
    border-radius: 28px;

    &.screenshot {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: 0.3s ease;
    }
  }
}

.game-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  text-shadow: 4px 4px 10px #000000;
}

.game-description {
  color: #fcdda0;
}

.game-info {
  margin-top: auto;
  padding-top: 8px;
  display: flex;
  justify-content: space-evenly;
  text-align: left;
  transition: 0.3s ease;
}

.game-info-block {
  padding: 8px 16px;
}

.game-info-value {
  font-weight: 700;
  color: var(--primary);
}

.game-info-name {
  font-size: 16px;
}

.game:hover {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15);

  .game-demo,
  .game-img .screenshot {
    opacity: 1;
  }

  .game-info {
    opacity: 0;
  }
}

@media (max-width: 1200px) {
  .game {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15);
    border-radius: 24px;
  }

  .game-img .screenshot {
    display: none;
  }

  .game-demo {
    position: static;
    opacity: 1;
    width: 100%;
  }

  .game:hover .game-info {
    opacity: 1;
  }
}

@media (max-width: 420px) {
  .game {
    padding: 12px;
  }

  .game-info-value {
    font-size: 18px;
  }

  .game-info {
    justify-content: space-between;
  }

  .game-info-block {
    padding: 8px 0;
  }
}
</style>
