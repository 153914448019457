import { createStore } from 'vuex';
import games from './games.js';

export default createStore({
  state: {
    games,
    gameUrl: '',
  },
  mutations: {
    gameUrl(state, payload) {
      state.gameUrl = payload;
    },
  },
});
