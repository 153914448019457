<template>
  <button target="_blank" class="demo-button" :class="{ released }" @click.prevent="openDemo">
    <span v-if="!released">COMING SOON</span>
    <template v-else-if="useIcon">
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="48" height="48" rx="16" />
        <path d="M34 24L19 32.6603L19 15.3397L34 24Z" />
      </svg>
      <span>DEMO</span>
    </template>
    <span v-else>PLAY DEMO</span>
  </button>
</template>
<script>
import DeviceDetector from 'device-detector-js';
// import { createHash } from 'crypto-browserify';
import createHmac from 'create-hmac';

const deviceDetector = new DeviceDetector();
const device = deviceDetector.parse(navigator.userAgent);
const isDesktop = device.device.type === 'desktop';

export default {
  props: {
    id: { type: Number, required: true },
    useIcon: { type: Boolean, default: true },
  },
  methods: {
    async openDemo() {
      const apiKey = 'test_key';
      const partnerId = '1';
      const gameId = this.id;
      const returnUrl = window.location.href.split('?')[0];

      const hmac = createHmac('sha256', apiKey);
      let data = 'demo' + partnerId + gameId;
      if (!isDesktop) data += returnUrl;
      data += apiKey;
      const hash = hmac.update(data).digest('hex');

      const url = 'https://games.pixmove.co/api/games/game/demo';

      let params = `?partnerId=${partnerId}&gameId=${gameId}&hash=${hash}`;
      if (!isDesktop) params += `&returnUrl=${returnUrl}`;

      const response = await fetch(url + params);
      const result = await response.json();

      if (result.status === 'error') {
        console.error(result.error);
        return;
      }

      if (isDesktop) {
        this.$store.commit('gameUrl', result.data.gameUrl);
      } else {
        window.location = result.data.gameUrl;
      }
    },
  },
  computed: {
    released() {
      return !!this.$store.state.games.find((g) => g.id === this.id).releaseDate;
    },
  },
};
</script>
<style lang="scss">
.demo-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  height: 62px;
  border-radius: 20px;
  background: var(--primary-3);
  transition: 0.3s ease;

  svg {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    margin: 0 16px 0 -8px;

    rect,
    path {
      transition: 0.3s ease;
    }

    rect {
      fill: var(--primary);
    }

    path {
      fill: var(--primary-4);
    }
  }

  span {
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    text-transform: uppercase;
    color: var(--primary-4);
  }

  &:hover {
    background: var(--primary);

    svg {
      rect {
        fill: var(--primary-3);
      }

      path {
        fill: var(--primary);
      }
    }
  }

  &:not(.released) {
    backdrop-filter: blur(2px);
    pointer-events: none;
    background: rgba(22, 70, 70, 0.7);

    span {
      color: var(--primary);
    }
  }

  @media (max-width: 600px) {
    height: 48px;

    span {
      font-size: 26px;
    }

    svg {
      width: 32px;
      height: 32px;
      margin: 0 12px 0 0;
    }
  }
}
</style>
