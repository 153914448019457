<template>
  <div class="games-view">
    <Header without-btn />
    <div class="container games-wrap">
      <h1>
        <span>all</span>
        <span>games</span>
      </h1>
      <div class="games-filter-wrap">
        <div class="games-filers">
          <template v-for="(filter, key) in filters" :key="key">
            <button
              v-for="value in filter.values"
              :key="value"
              :class="{ active: value === filter.active }"
              @click="setUpFilteredGames(filter, key, value)"
            >
              #{{ value }}
            </button>
          </template>
        </div>
      </div>
      <div class="games">
        <GameCard
          v-for="game in filteredGames"
          :key="game.id"
          :id="game.id"
          :type="game.type"
          :preview="game.preview"
          :screen="game.screens[0]"
          :title="game.title"
          :desc="game.descShort"
          :category="game.info.category"
          :max-win="game.info.maxWin"
          :volatility="game.info.volatility"
        />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import GameCard from '@/components/GameCard.vue';

export default {
  name: 'GamesView',
  components: { Header, Footer, GameCard },
  data(vm) {
    return {
      currentFilter: {},
      filters: {
        all: {
          values: ['all'],
          active: '',
          cb: () => true,
        },
        category: {
          values: ['slot', 'instant'],
          active: '',
          cb: (game) => game.info.category.toLowerCase() === vm.filters.category.active,
        },
        status: {
          values: ['released', 'coming soon'],
          active: '',
          cb: (game) =>
            vm.filters.status.active === 'released' ? !!game.releaseDate : !game.releaseDate,
        },
      },
    };
  },
  computed: {
    filteredGames() {
      let games = this.$store.state.games;
      for (const key in this.filters) {
        if (this.filters[key].active) {
          games = games.filter(this.filters[key].cb);
        }
      }
      return games;
    },
  },
  methods: {
    changeFilter(filter, value) {
      if (value === 'all') {
        for (let filter in this.filters) {
          this.filters[filter].active = filter === 'all' ? value : '';
        }
      } else {
        this.filters.all.active = '';
        filter.active = filter.active === value ? '' : value;
      }
    },
    changeQueryString(filter, key, value) {
      if (key === 'all') {
        this.currentFilter = {};
      } else if (key in this.currentFilter && this.currentFilter[key] === value) {
        delete this.currentFilter[key];
      } else {
        this.currentFilter[key] = value;
      }
      this.$router.replace({ name: 'games', query: this.currentFilter });
    },
    setUpFilteredGames(filter, key, value) {
      this.changeQueryString(filter, key, value);
      this.changeFilter(filter, value);
    },
  },
  created() {
    this.currentFilter = { ...this.$route.query };
    Object.keys(this.currentFilter).forEach((filter) => {
      this.changeFilter(this.filters[filter], this.currentFilter[filter]);
    });
  },
};
</script>

<style lang="scss" scoped>
.games-view {
  display: flex;
  flex-direction: column;
}

.games-wrap {
  padding: 20vh 0 50px;
}

.games {
  margin: 16px auto 0;
  display: grid;
  grid-gap: 20px;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 100%;
}

.games-filers {
  display: flex;
  justify-content: center;
  margin: 40px 0 0;

  button {
    flex-shrink: 0;
    font-weight: 400;
    font-size: 32px;
    line-height: 120%;
    color: var(--primary);
    padding: 13px 16px;
    border-radius: 20px;
    transition: 0.3s ease;

    &:hover {
      background: rgba(255, 223, 160, 0.15);
    }

    &.active {
      background: rgba(255, 223, 160, 0.3);
    }

    & + button {
      margin-left: 14px;
    }
  }
}

@media (max-width: 1560px) {
  .games {
    width: 848px;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 900px) {
  .games {
    grid-template-columns: 1fr;
  }

  .games-wrap {
    padding: 120px 0 50px;
  }

  .games-filter-wrap {
    margin: 0 -5vw;
    overflow: hidden;
  }

  .games-filers {
    justify-content: flex-start;
    overflow: auto;
    overflow: overlay;
    margin: 40px 0 -20px 0;
    padding: 0 0 20px 0;

    &::before,
    &::after {
      content: '';
      width: 5vw;
      height: 1px;
      flex-shrink: 0;
    }
  }
}

@media (max-width: 600px) {
  .games-wrap {
    padding: 100px 0 50px;
  }

  .games {
    margin: 12px auto 0;
    grid-template-columns: 1fr;
  }

  .games-filers {
    margin: 10px 0 -20px 0;

    button {
      font-size: 20px;
      padding: 8px 12px;
      border-radius: 12px;

      & + button {
        margin-left: 4px;
      }
    }
  }
}
</style>
