<template>
  <transition>
    <div v-if="$store.state.gameUrl" class="game-wrap" :class="{ fullscreen }">
      <div class="game">
        <div class="controls">
          <button @click="$store.commit('gameUrl', '')">
            <img src="@/assets/icons/close-small.svg" alt="" />
          </button>
          <button @click="fullscreen = !fullscreen">
            <img v-if="fullscreen" src="@/assets/icons/mobile.svg" alt="" />
            <img v-else src="@/assets/icons/desktop.svg" alt="" />
          </button>
        </div>
        <iframe :src="$store.state.gameUrl" frameborder="0"></iframe>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      fullscreen: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.game-wrap {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(6px);
  transition: opacity 0.3s ease;

  &.v-enter-from,
  &.v-leave-to {
    opacity: 0;
  }
}

.game {
  position: relative;
  margin: auto;
  width: calc((100vh - 64px) * 9 / 18);
  height: calc(100vh - 64px);
  background: black;

  @media (min-width: 2000px) {
    height: 80vh;
    width: calc(80vh * 9 / 18);
  }

  iframe {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.controls {
  position: absolute;
  top: 12px;
  right: -76px;

  button {
    display: block;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 100px;
    margin: 0 0 16px 0;
    padding: 8px;
    transition: 0.3s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }

    img {
      width: 40px;
      height: 40px;
    }
  }
}

.game-wrap.fullscreen {
  .game {
    width: calc(100% - 64px);
    height: calc(100% - 64px);
  }
  .controls {
    top: 24px;
    right: 24px;
  }
}
</style>
