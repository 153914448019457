import { createRouter, createWebHistory } from 'vue-router';
// import HomeView from '@/views/HomeView.vue';
import GameView from '@/views/GameView.vue';
import CatalogView from '@/views/CatalogView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    // component: HomeView,
    redirect: { name: 'games' },
  },
  {
    path: '/games',
    name: 'games',
    component: CatalogView,
  },
  {
    path: '/games/:type',
    name: 'game',
    component: GameView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
